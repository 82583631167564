import React from 'react';
import './Column.css';

interface ColumnProps {
  children: React.ReactNode;
}

function Column({ children }: ColumnProps) {
  return <div className="column">{children}</div>;
}

export default Column;
