import Header from './components/Header';
import Column from './components/Column';
import Headline from './components/Headline';
import headlines from './data/headlines.json';
import './App.css';

function App() {
  // headlines sorted highest importance first and then divided evenly into 3 columns (for however many there are)
  const columnCount = 3;
  const headlinesSorted = headlines.columns.flat();
  const columns = headlinesSorted.reduce((acc: (typeof headlinesSorted)[], headline, index) => {
    const columnIndex = index % columnCount;
    acc[columnIndex] = acc[columnIndex] || [];
    acc[columnIndex].push(headline);
    return acc;
  }, []);

  return (
    <div className="App">
      <Header />

      <div className="main-headline-container">
        <Headline text={headlines.main.text} url={headlines.main.url} isMain={true} />
      </div>

      <div className="content">
        {columns.map((col, index: number) => (
          <Column key={index}>
            {col.map(({ text, url }, idx: number) => (
              <Headline key={idx} text={text} url={url} />
            ))}
          </Column>
        ))}
      </div>
    </div>
  );
}

export default App;
