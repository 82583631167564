import React from 'react';
import './Headline.css';

interface HeadlineProps {
  text: string;
  url: string;
  isMain?: boolean;
}

function Headline({ text, url, isMain = false }: HeadlineProps) {
  return (
    <div className={isMain ? 'main-headline headline' : 'headline'}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    </div>
  );
}

export default Headline;
